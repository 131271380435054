@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&family=Poppins:wght@300;400;500;600&display=swap);
@font-face {
  font-family: 'starcraft';
  src: url(/static/media/starcraft_normal-webfont.9c782422.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: 'Poppins', sans-serif;
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}

.starcraft {
  font-family: 'starcraft';
  font-size: 2.5rem;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 10px;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 50px;
}

textarea {
  resize: none;
}
.top {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .home-name {
    font-size: 2rem;
  }

  .starcraft {
    font-size: 1.5rem;
  }
}

